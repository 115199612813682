import {
  Button,
  Toolbar,
  AppBar,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Paper,
  Link,
} from "@mui/material";
import styles from "../styles/AboutDataCollection.module.css";

const AboutDataCollection: React.FC = () => {
  return (
    <div>
      {/* HEADER */}
      <div>
        <AppBar
          position="fixed"
          sx={{ bgcolor: process.env.REACT_APP_COLOR_PRIMARY_MAIN }}
        >
          <Toolbar>
            <div className={styles.title}>
              <Box
                sx={{ color: "#FAFAFA", pl: 0.5 }}
              >{`お客さまご利用端末からの情報の外部送信について`}</Box>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      {/* BODY */}
      <div>
        <Container maxWidth="lg">
          <Box
            component="h4"
            sx={{ pl: 2, py: 2, mt: 10 }}
            style={{ backgroundColor: "#F2F2F2" }}
          >
            当サイトにおける情報収集について
          </Box>
          <Box component="h4">パーソナルデータを利用している外部サービス</Box>

          <Typography>
            当サイトでは、以下の第三者が提供するサービスを利用するため、パーソナルデータを送信しています。これらのサービスでパーソナルデータがどのように扱われるかについては、それぞれのプライバシーポリシーをご確認ください。お使いのブラウザにおいて、これらのサービスを無効化したい場合は、それぞれの無効化（オプトアウト）ページをご確認ください。
          </Typography>

          <TableContainer
            component={Paper}
            style={{ marginBottom: "30px", marginTop: "30px" }}
          >
            <Table size="small" aria-label="group table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#F2F2F2" }}>
                  <TableCell align="center">種類</TableCell>
                  <TableCell align="center">サービス名</TableCell>
                  <TableCell align="center">提供者</TableCell>
                  <TableCell align="center">プライバシーポリシー</TableCell>
                  <TableCell align="center">オブアウト（無効化）</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">アクセス解析</TableCell>
                  <TableCell align="center">
                    <Link
                      target="_blank"
                      href="https://analytics.google.com/analytics/web/"
                      color="#000000"
                    >
                      Google アナリティクス
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      target="_blank"
                      href="https://www.google.com/"
                      color="#000000"
                    >
                      Google
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      target="_blank"
                      href="https://policies.google.com/privacy"
                      color="#000000"
                    >
                      ページを開く
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      target="_blank"
                      href="https://tools.google.com/dlpage/gaoptout/"
                      color="#000000"
                    >
                      ページを開く
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box component="h4">パーソナルデータを利用していない外部サービス</Box>
          <Typography>
            当サイトでは、ウェブサイト運用、利便性向上のために以下の第三者が提供するサービスを利用しています。これらのサービスではパーソナルデータの利用は行っていませんが、ブラウザが自動的に送信する情報が送信されています。
          </Typography>
          <TableContainer
            component={Paper}
            style={{ marginBottom: "30px", marginTop: "30px" }}
          >
            <Table size="small" aria-label="group table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#F2F2F2" }}>
                  <TableCell align="center">種類</TableCell>
                  <TableCell align="center">サービス名</TableCell>
                  <TableCell align="center">提供者</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">タグマネージャ</TableCell>
                  <TableCell align="center">
                    <Link
                      target="_blank"
                      href="https://marketingplatform.google.com/about/tag-manager/"
                      color="#000000"
                    >
                      Googleタグマネージャー
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      target="_blank"
                      href="https://www.google.com/"
                      color="#000000"
                    >
                      Google
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">ウェブツール</TableCell>
                  <TableCell align="center">
                    <Link
                      target="_blank"
                      href="https://www.datadoghq.com/ja/"
                      color="#000000"
                    >
                      Datadog
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    <Link
                      target="_blank"
                      href="https://www.datadoghq.com/ja/"
                      color="#000000"
                    >
                      Datadog, Inc.
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box component="h4">パーソナルデータとは？</Box>
          <Typography sx={{ mb: "5px" }}>
            パーソナルデータとは、「個人情報」に限定されない、個人の行動·状態に関するデータや、個人の利用しているブラウザに紐づく情報のことを指します。
            ウェブサイトへの訪問時には、以下のデータが各サービス提供事業者へブラウザから自動的に送信されています。
          </Typography>
          <Typography sx={{ ml: "5px" }}>
            ・クッキー情報 -
            訪問者のブラウザを特定するための文字列等が記録されています。
          </Typography>
          <Typography sx={{ ml: "5px" }}>
            ・IPアドレス -
            おおよそのアクセス元の地域、アクセス元の企業、回線の種類等を判別できます。
          </Typography>
          <Typography sx={{ ml: "5px" }}>
            ・閲覧ページURL - 閲覧しているウェブサイトのURLです。
          </Typography>
          <Typography sx={{ ml: "5px" }}>
            ・リファラ（参照元URL） - 一つ前のウェブページのURLです。
          </Typography>
          <Typography sx={{ ml: "5px" }}>
            ・アクセス日時 - アクセスした日時、滞在時間等の算出に用いられます。
          </Typography>
          <Typography sx={{ ml: "5px", mb: "5px" }}>
            ・ユーザーエージェント -
            利用しているブラウザの種類、OSの種類を判別できます。
          </Typography>
          <Typography>
            また、JavaScriptを実行することによって取得できる情報を送信している場合があります。
            これらの情報は個人情報保護法上の個人情報にはあたらない場合がありますが、個人のプライバシーに関する情報であるため、お知らせしています。
          </Typography>
          <Box component="h4">
            「パーソナルデータを利用している外部サービス」について
          </Box>
          <Typography>
            パーソナルデータを利用している外部サービスは、パーソナルデータの情報を組み合わせることで、ウェブサイトの訪問者の住んでいる地域、勤務先、趣味・嗜好、性別、年代などを予測し、ウェブサイト訪問者に合わせた、コンテンツや広告を表示したりアクセスの解析をしてウェブサイトのデザインやユーザーエクスペリエンスの改善を行ったりしています。
            サービスの詳細、利用しているデータ項目、用途は、それぞれのサービス提供者のウェブサイトをご確認ください。
          </Typography>
          <Box component="h4">
            「パーソナルデータを利用していない外部サービス」について
          </Box>
          <Typography>
            パーソナルデータを利用していない外部サービスは、同様の情報を受信しているものの、訪問者のブラウザ単位での特定を行わず、個人のプライバシーに関する情報を利用していないサービスです。サービスの詳細については、サービス提供者のウェブサイトをご確認ください。
            なお、当社の情報収集モジュールによる情報収集については、以下からより詳しくご確認いただくことができます。
          </Typography>
          <Typography
            sx={{
              mt: "10px",
            }}
          >
            <Link
              target="_blank"
              href="https://www.docomo.ne.jp/utility/term/handling_information/"
              color="#000000"
            >
              お客さまが閲覧するWebサイトでのNTTドコモによる情報収集と利用について
            </Link>
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Button
              type="button"
              onClick={() => window.close()}
              color="inherit"
              variant="contained"
              sx={{
                width: "260px",
                height: "45px",
                textTransform: "none",
                my: "20px",
              }}
            >
              閉じる
            </Button>
          </Box>
        </Container>
      </div>
      {/* FOOTER */}
      <div>
        <Typography
          variant="body2"
          align="center"
          sx={{
            textTransform: "none",
            bgcolor: process.env.REACT_APP_COLOR_PRIMARY_MAIN,
            color: "#FAFAFA",
            py: "2px",
          }}
        >
          {"© NTT DOCOMO"}
        </Typography>
      </div>
    </div>
  );
};

export default AboutDataCollection;
