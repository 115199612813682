//サービス一覧取得
export const fetchServiceNameList = /* GraphQL */ `
  query MyQuery($partitionKey: String, $filter: ModelUserCIMPFFilterInput) {
    services: userListCIMPFs(partitionKey: $partitionKey, filter: $filter) {
      items {
        serviceId: sortKey
        serviceName
        isFileAttache
      }
    }
  }
`;

//ServiceIdに紐づくサービス名取得
export const fetchServiceName = /* GraphQL */ `
  query MyQuery(
    $partitionKey: String = "ServiceInfomation"
    $sortKey: ModelUserStringKeyConditionInput
  ) {
    serviceIdName: userListCIMPFs(
      partitionKey: $partitionKey
      sortKey: $sortKey
    ) {
      items {
        serviceName
      }
    }
  }
`;

/** アカウント識別子取得 */
export const getSession = /* GraphQL */ `
  query GetSession {
    isLoggedIn {
      accountIdentifier
      maskdocomoId
    }
  }
`;

//** チケット一覧取得(URLにサービスIDが存在しない) */
export const getTicketInfomation = /* GraphQL */ `
  query MyQuery(
    $partitionKey: String!
    $accountIdentifier: ModelUserStringKeyConditionInput
    $filter: ModelUserCIMPFFilterInput
    $sortDirection: ModelUserSortDirection
    $cognitoApiName: String
  ) {
    displayTicketInfo: userListTicketInfomation(
      partitionKey: $partitionKey
      accountIdentifier: $accountIdentifier
      filter: $filter
      sortDirection: $sortDirection
      cognitoApiName: $cognitoApiName
    ) {
      items {
        ticketNumber
        serviceName
        createdAt
        userServiceId
        inquiry
      }
    }
  }
`;

//** チケット一覧取得(URLにサービスIDが存在する) */
export const getTicketInfoServiceId = /* GraphQL */ `
  query MyQuery(
    $partitionKey: String!
    $accountIdentifier: ModelUserStringKeyConditionInput
    $sortMatchKey: String!
    $filter: ModelUserCIMPFFilterInput
    $filterServiceId: ModelUserCIMPFFilterInput
    $sortDirection: ModelUserSortDirection
    $cognitoApiName: String
  ) {
    displayTicketInfoId: userListTicketInfomationServiceId(
      partitionKey: $partitionKey
      accountIdentifier: $accountIdentifier
      sortMatchKey: $sortMatchKey
      filter: $filter
      filterServiceId: $filterServiceId
      sortDirection: $sortDirection
      cognitoApiName: $cognitoApiName
    ) {
      items {
        ticketNumber
        serviceName
        createdAt
        userServiceId
        inquiry
      }
    }
  }
`;

// チケット番号からサービスIDを取得する
export const serviceIdFromTicketNumber = /* GraphQL */ `
  query fetchServiceIdFromTicketNumber(
    $partitionKey: String!
    $ticketNumber: String!
  ) {
    ticketInfomation: userGetCIMPF(
      partitionKey: $partitionKey
      sortKey: $ticketNumber
    ) {
      serviceId: userServiceId
    }
  }
`;

//追加質問画面：お問合せ内容
export const fetchAddQuestionResources = /* GraphQL */ `
  query fetchAddQuestionResources(
    $partitionKey: String!
    $ticketNumber: String!
    $chatsFilter: String!
    $filePartitionKey: String!
  ) {
    ticketInfomation: getUserTicketInfomation(
      partitionKey: $partitionKey
      sortKey: $ticketNumber
    ) {
      ticketNumber
      serviceId
      serviceName
      createdAt
      inquiry
      status
    }
    chats: getUserChatList(partitionKey: $ticketNumber, filter: $chatsFilter) {
      items {
        index: sortKey
        inquiry
        answer
        createdAt
      }
    }
    fileInfo: userListCIMPFs(partitionKey: $filePartitionKey) {
      items {
        index: sortKey
        data {
          fileName
          isDeleted
          objName
          size
          isChecked
          isVirus
        }
      }
    }
  }
`;

//追加質問画面：チケット情報アカ識突合
export const matchTicketInfoUserAccountId = /* GraphQL */ `
  query matchTicketInfoUserAccountId(
    $partitionKey: String!
    $sortKey: String!
    $accountIdentifier: String!
  ) {
    matchTicketInfoUserAccountId(
      partitionKey: $partitionKey
      sortKey: $sortKey
      accountIdentifier: $accountIdentifier
    ) {
      isMatched
    }
  }
`;

/** お知らせ一覧取得(URLにサービスIDが存在しない) */
export const getNoticeList = /* GraphQL */ `
  query GetNoticeList(
    $notification: String!
    $filter: ModelUserCIMPFFilterInput
  ) {
    noticeListInfo: noticeList(partitionKey: $notification, filter: $filter) {
      items {
        notice
        scheduledAt
      }
    }
  }
`;

/** お知らせ一覧取得(URLにサービスIDが存在する) */
export const getNoticeServiceIdList = /* GraphQL */ `
  query GetNoticeList(
    $notification: String!
    $sortMatchKey: String!
    $filterServiceId: ModelUserCIMPFFilterInput
  ) {
    noticeServiceIdListInfo: noticeListServiceId(
      partitionKey: $notification
      sortMatchKey: $sortMatchKey
      filterServiceId: $filterServiceId
    ) {
      items {
        notice
        scheduledAt
      }
    }
  }
`;

/** サブカテゴリの取得 */
export const getSubCategoryList = /* GraphQL */ `
  query subCategories($serviceIds: [String]) {
    subCategories: searchSubcategories(
      serviceIds: $serviceIds
      isActive: true
    ) {
      items {
        index: sortKey
        order
        name: subCategoryName
      }
      total
    }
  }
`;

/** サービスIDに応じた注意文言の取得 */
export const getAttentionMessage = /* GraphQL */ `
  query GetAttentionMessage($partitionKey: String!, $sortKey: String!) {
    attentionMessage: userGetCIMPF(
      partitionKey: $partitionKey
      sortKey: $sortKey
    ) {
      message
    }
  }
`;

/** サービスIDに応じた戻り先URLの取得 */
export const getReturnUrl = /* GraphQL */ `
  query GetReturnUrl($serviceId: String!) {
    returnUrl: userGetReturnUrl(serviceId: $serviceId) {
      returnServiceName
      url
    }
  }
`;

/** ステータス情報 */
export const getStatusInformation = /* GraphQL */ `
  query GetCIMPF($partitionKey: String!, $sortKey: String!) {
    getCIMPF(partitionKey: $partitionKey, sortKey: $sortKey) {
      status
    }
  }
`;

/** リリース時情報 */
export const getReleaseFlg = /* GraphQL */ `
  query GetCIMPF($partitionKey: String!, $sortKey: String!) {
    getCIMPF(partitionKey: $partitionKey, sortKey: $sortKey) {
      releaseFlg
    }
  }
`;

/** マスクドコモID */
export const fetchMaskDocomoId = /* GraphQL */ `
  query CallUserInfo($accountIdentifier: String, $input: CircusApiInput) {
    userAuth: getMaskDocomoId(
      accountIdentifier: $accountIdentifier
      input: $input
    ) {
      accountIdentifier
      maskdocomoId
      statusCode
    }
  }
`;

/** 添付ファイル可能時用注意文言取得 */
export const getAttachmentMesseage = /* GraphQL */ `
  query getAttachmentMesseage($partitionKey: String!, $sortKey: String!) {
    attachmentMessage: userGetCIMPF(
      partitionKey: $partitionKey
      sortKey: $sortKey
    ) {
      message
    }
  }
`;

/** 登録済み画像ダウンロード */
export const fetchImageDownload = /* GraphQL */ `
  query fetchImageDownload(
    $ticketNumber: String
    $chatNumber: String
    $objName: String
  ) {
    downloadObjName(
      ticketNumber: $ticketNumber
      chatNumber: $chatNumber
      objName: $objName
    ) {
      url
      statusCode
    }
  }
`;

/** 画像アップロード先署名付きURL取得 */
export const fetchImageUploadUrl = /* GraphQL */ `
  query fetchImageUploadUrl(
    $accountId: String!
    $fileName: String!
    $fileType: String!
  ) {
    getUploadUrl(
      accountId: $accountId
      fileName: $fileName
      fileType: $fileType
    ) {
      statusCode
      body {
        s3Url
        objName
      }
    }
  }
`;

/** 一時保存画像ダウンロード */
export const fetchTemporarilyImage = /* GraphQL */ `
  query fetchTemporarilyImage($accountIdentifier: String, $objName: String) {
    downloadTemporarily(
      accountIdentifier: $accountIdentifier
      objName: $objName
    ) {
      url
      statusCode
    }
  }
`;

// 画像アップロード可能サービス判定フラグ取得
export const fetchServiceImageFlag = /* GraphQL */ `
  query fetchServiceImageFlag($partitionKey: String!, $sortKey: String!) {
    isFileAttache: userGetCIMPF(
      partitionKey: $partitionKey
      sortKey: $sortKey
    ) {
      isFileAttache
    }
  }
`;

// 画像投稿用注意文言取得
export const fetchImageNotes = /* GraphQL */ `
  query fetchImageNotes($partitionKey: String!, $sortKey: String!) {
    imageNotes: userGetCIMPF(partitionKey: $partitionKey, sortKey: $sortKey) {
      data {
        text
      }
    }
  }
`;

//  チケット情報のサービスID取得
export const fetchTicketServiceId = /* GraphQL */ `
  query fetchTicketServiceId($partitionKey: String!, $sortKey: String!) {
    userGetCIMPF(partitionKey: $partitionKey, sortKey: $sortKey) {
      serviceId
    }
  }
`;

/** ウイルスチェック */
export const fetchCheckImage = /* GraphQL */ `
  query fetchCheckImage(
    $accountId: String!
    $objName: String!
    $fileName: String!
    $size: String!
  ) {
    checkImage(
      accountId: $accountId
      objName: $objName
      fileName: $fileName
      size: $size
    ) {
      statusCode
      body {
        fileName
        isDeleted
        objName
        size
      }
    }
  }
`;

// 画像投稿ウイルスチェック進行確認フラグ取得
export const checkDynamodb = /* GraphQL */ `
  query checkDynamodb(
    $partitionKey: String!
    $sortKey: String!
    $objName: String!
  ) {
    checkDynamodb(
      partitionKey: $partitionKey
      sortKey: $sortKey
      objName: $objName
    ) {
      statusCode
      body {
        objName
        isChecked
        isVirus
      }
    }
  }
`;

// リビジョン取得
export const getRevision = /* GraphQL */ `
  query GetRevision($partitionKey: String!, $sortKey: String!) {
    release: userGetCIMPF(partitionKey: $partitionKey, sortKey: $sortKey) {
      target: sortKey
      revision
    }
  }
`;

/** サービス終了情報 */
export const getServiceCloseFlag = /* GraphQL */ `
  query GetCIMPF($partitionKey: String!, $sortKey: String!) {
    getCIMPF(partitionKey: $partitionKey, sortKey: $sortKey) {
      releaseFlg
      sortKey
      serviceCloseFlag
      telServiceFlg
    }
  }
`;

/** FAQ 表示フラグ */
export const getIsEnabledFAQ = /* GraphQL */ `
  query GetIsEnabledFAQ(
    $partitionKey: String = "ServiceInfomation"
    $serviceId: String!
  ) {
    serviceInfomation: getCIMPF(
      partitionKey: $partitionKey
      sortKey: $serviceId
    ) {
      isEnabledFAQ
    }
  }
`;

/** FAQ データ取得 */
export const fetchFaqData = /* GraphQL */ `
  query ListFaqData($key: String!) {
    faqData: listFaqData(key: $key) {
      key: partitionKey
      id: sortKey
      priority
      question
      answer
    }
  }
`;

/** 現在日時取得(タイムスタンプ Asia/Tokyo) */
export const getCurrentTime = /* GraphQL */ `
  query GetCurrentTime {
    getCurrentTime {
      currentTime
    }
  }
`;

//////////////////////// 解約サービス用 /////////////////////////////////
// チケット一覧取得(解約サービス用)
export const getTicketInfoCancel = /* GraphQL */ `
  query MyQuery(
    $partitionKey: String!
    $accountIdentifier: ModelUserStringKeyConditionInput
    $sortMatchKey: String!
    $filter: ModelUserCIMPFFilterInput
    $filterServiceId: ModelUserCIMPFFilterInput
    $sortDirection: ModelUserSortDirection
    $cognitoApiName: String
  ) {
    displayTicketInfoId: userListTicketInfomationServiceId(
      partitionKey: $partitionKey
      accountIdentifier: $accountIdentifier
      sortMatchKey: $sortMatchKey
      filter: $filter
      filterServiceId: $filterServiceId
      sortDirection: $sortDirection
      cognitoApiName: $cognitoApiName
    ) {
      items {
        ticketNumber
        serviceName
        subCategoryName
        createdAt
        userServiceId
        inquiry
      }
    }
  }
`;
