import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../app/store";
import {
  selectRevision,
  getUserRevision,
  setIsRevisionMatch,
} from "../reducks/slice";
import { API, graphqlOperation } from "aws-amplify";
import { onSubscribeRevision } from "graphql/customSubscriptions";
import { revisionTarget } from "common/common";

/**
 * リビジョン取得
 */
export const useGetRevision = () => {
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserRevision(revisionTarget));
  }, [dispatch]);
};

/**
 * リビジョン確認
 * @param isLoading
 */
export const useCheckRevision = (isLoading: boolean) => {
  const dispatch: AppDispatch = useDispatch();
  const revision = useSelector(selectRevision);

  useEffect(() => {
    // リビジョンが取得できてローディング中じゃない場合
    if (revision && !isLoading) {
      const storedRevision = sessionStorage.getItem("v");
      if (!storedRevision || storedRevision === "0") {
        sessionStorage.setItem("v", revision);
      } else if (storedRevision !== revision) {
        dispatch(setIsRevisionMatch(false));
      }
    }
  }, [revision, isLoading, dispatch]);
};

/**
 * リリース通知 subscribe
 * @param isReady
 */
export const useSubscribeRevision = (isReady: boolean) => {
  type revisionSubscription = {
    value: {
      data: { onSubscribeRevision: { revision: string; target: string } };
    };
  };
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    let unsubscribe: any;
    if (isReady) {
      // リリースバージョン subscribe
      const subscription = API.graphql(
        graphqlOperation(onSubscribeRevision, { revisionTarget })
      );
      if ("subscribe" in subscription) {
        const sub = subscription.subscribe({
          next: ({ value: { data } }: revisionSubscription) => {
            console.log(data);
            // 現行のリビジョン
            const revision = sessionStorage.getItem("v");
            // 通知対象が user で、現行のリビジョンと異なる場合にモーダル表示
            if (
              data.onSubscribeRevision.target === revisionTarget &&
              data.onSubscribeRevision.revision !== revision
            ) {
              dispatch(setIsRevisionMatch(false));
            }
          },
          /** エラー */
          // subscription できなかったとしてもトップページでリビジョン確認するのでなにもしない
          error: (error) => console.log(error),
        });
        unsubscribe = () => {
          sub.unsubscribe();
        };
      }
    }
    return unsubscribe;
  }, [isReady, dispatch]);
};
