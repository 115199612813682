import { PAGES } from "./type";

export const title: PAGES = {
  top: "トップ画面",
  input: "お問合せ入力画面",
  confirm: "送信確認画面",
  send: "送信完了画面",
  addQuestions: "追加質問画面",
  addQuestionConfirm: "追加質問送信確認画面",
  error: "エラー画面",
  cancelTop: "ご解約トップ画面",
  cancelInput: "ご解約申請画面",
  cancelConfirm: "ご解約申請確認画面",
  cancelSend: "ご解約申請完了画面",
  cancelAddQuestions: "ご解約追加質問画面",
  cancelAddQuestionConfirm: "ご解約追加質問送信確認画面",
  aboutdatacollection: "外部送信規律確認画面",
};

export const path: PAGES = {
  top: "/",
  input: "/input",
  confirm: "/confirm",
  send: "/send",
  addQuestions: "/addQuestions",
  addQuestionConfirm: "/addQuestionConfirm",
  error: "/error",
  // 解約申請モード
  cancelTop: "/cancel",
  cancelInput: "/cancel/input",
  cancelConfirm: "/cancel/confirm",
  cancelSend: "/cancel/send",
  cancelAddQuestions: "/cancel/addQuestions",
  cancelAddQuestionConfirm: "/cancel/addQuestionConfirm",
  // 外部送信規律対応
  aboutdatacollection: "/aboutdatacollection",
};
