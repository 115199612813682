import { RootState } from "./../app/store";
import {
  EDITED_CONTENT,
  ADD_INQUIRY_CONTENT,
  SERVICES,
  ALL_STATE,
  IMAGE_PREVIEW,
  IMAGE_FILE_INFO,
} from "./types";
import { addTicket, uploadAllImages } from "graphql/customMutations";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { initialState } from "./initialState";
import {
  checkDynamodb,
  fetchCheckImage,
  fetchImageDownload,
  fetchImageNotes,
  fetchImageUploadUrl,
  fetchServiceImageFlag,
  fetchTemporarilyImage,
  fetchTicketServiceId,
  getServiceCloseFlag,
  getRevision,
  getIsEnabledFAQ,
  fetchFaqData,
  getCurrentTime,
} from "graphql/customQueries";
import {
  serviceIdFromTicketNumber,
  fetchServiceName,
  fetchServiceNameList,
  getTicketInfomation,
  fetchAddQuestionResources,
  getNoticeList,
  getNoticeServiceIdList,
  getTicketInfoServiceId,
  getSubCategoryList,
  getReturnUrl,
  getAttentionMessage,
  getStatusInformation,
  getReleaseFlg,
  getTicketInfoCancel,
  fetchMaskDocomoId,
  getAttachmentMesseage,
} from "../graphql/customQueries";
import {
  regenerationSession,
  addQuestionTicket,
  addLog,
} from "../graphql/customMutations";
import {
  getAuthHeaderItems,
  authRedirect,
  setSession,
} from "graphql/call/auth";
import {
  apiName,
  CANCEL_SERVICE_ID,
  connectionV1API,
  connectionV2API,
  DefaultAttentionMsgKey,
  DefaultReturnUrlKey,
  API_NAME_CANCEL,
  SubCategoryUnselected,
  CiRCUSuw8001,
  revisionKey,
} from "common/common";
import { authConst, authenticationType } from "common/const";

// #######################################################
// 解約申請登録
/** TODO subCategoryに命名統一すべきだが、ダウンタイムが発生する為、修正は見送り */
export const fetchAsyncCancelTicket = createAsyncThunk(
  "cancelTicket",
  async (items: any, thunkAPI) => {
    try {
      /** user agent */
      const ua = navigator.userAgent ? navigator.userAgent : "";
      const res = await API.graphql(
        graphqlOperation(addTicket, {
          cognitoApiName: API_NAME_CANCEL,
          tableName: process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME,
          input: {
            accountIdentifier: items.authInfo.accountIdentifier,
            inquiry:
              "解約申請\nその他ご要望あれば続けてご記載ください\n" +
              items.editedContent.inquiryContent,
            serviceId: CANCEL_SERVICE_ID,
            incidentDate: "",
            incidentTime: "",
            serviceCategory: items.editedContent.subCategory,
            userAgent: ua,
            occurrenceDate: 0,
          },
        }),
        getAuthHeaderItems()
      );
      const resData = (res: any) => {
        return res.data;
      };
      // @ts-ignore
      return resData(res);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const setUserAuthInfo = createAsyncThunk(
  "setUserAuthInfo",
  async (accountIdentifier: string, thunkAPI) => {
    try {
      const input = {
        type: CiRCUSuw8001.type,
        request: CiRCUSuw8001.requestUser,
        accountIdentifier,
      };
      connectionV1API();
      const res = await API.graphql(
        graphqlOperation(fetchMaskDocomoId, { input })
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getServiceReleaseFlg = async () => {
  const params = {
    partitionKey: "ServiceInfomation",
    sortKey: CANCEL_SERVICE_ID,
  };
  try {
    return await API.graphql(graphqlOperation(getReleaseFlg, params));
  } catch (e) {
    throw new Error("getServiceReleaseFlg error");
  }
};

// チケット一覧取得(解約サービス用)
export const fetchAsyncTicketInfoCancel = createAsyncThunk(
  "fetchAsyncTicketInfoCancel",
  async (
    {
      accountIdentifier,
    }: {
      accountIdentifier: string;
    },
    thunkAPI
  ) => {
    const serviceId = CANCEL_SERVICE_ID;
    const ticketFilter = {
      or: [
        { status: { eq: 1 } },
        { status: { eq: 2 } },
        { status: { eq: 3 } },
        { status: { eq: 4 } },
        { status: { eq: 5 } },
        { status: { eq: 7 } },
        { status: { eq: 9 } },
      ],
    };
    const ticketFilterServiceId = {
      and: [
        { userServiceId: { eq: serviceId } },
        {
          or: [
            { status: { eq: 1 } },
            { status: { eq: 2 } },
            { status: { eq: 3 } },
            { status: { eq: 4 } },
            { status: { eq: 5 } },
            { status: { eq: 7 } },
            { status: { eq: 9 } },
          ],
        },
      ],
    };
    const params = {
      partitionKey: "TicketInfomation",
      accountIdentifier: { eq: accountIdentifier },
      sortMatchKey: serviceId,
      filter: ticketFilter,
      filterServiceId: ticketFilterServiceId,
      cognitoApiName: "userAcceptTicketOfCancel",
      sortDirection: "DESC",
    };
    try {
      const res = await API.graphql(
        graphqlOperation(getTicketInfoCancel, params),
        getAuthHeaderItems()
      );
      //@ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// #######################################################

export const createSession = createAsyncThunk(
  "createSession",
  async (args, thunkAPI) => {
    try {
      /** regenerationSession */
      const res = await API.graphql(
        graphqlOperation(regenerationSession, {
          tableName: process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME,
        }),
        getAuthHeaderItems()
      );
      const resData = (res: any) => {
        return res.data;
      };
      // @ts-ignore
      return resData(res);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
export const fetchAsyncGetNoticeList = createAsyncThunk(
  "getNoticeList",
  async () => {
    const res = await new Promise((resolve, reject) => {});
    return res;
  }
);
export const fetchAsyncGetAcceptingInquiries = createAsyncThunk(
  "getAcceptingInquiries",
  async () => {
    const res = await new Promise((resolve, reject) => {});
    return res;
  }
);
export const fetchAsyncGetServiceList = createAsyncThunk(
  "getServiceList",
  async () => {
    const res = await new Promise((resolve, reject) => {});
    return res;
  }
);

/** TODO subCategoryに命名統一すべきだが、ダウンタイムが発生するため修正見送り */
export const fetchAsyncAddTicket = createAsyncThunk(
  "addTicket",
  async (items: any, thunkAPI) => {
    try {
      let files = null;
      if (items.filesInfo.length !== 0 && items.filesInfo !== null) {
        files = items.filesInfo.map((item: any) => {
          return {
            fileName: item.fileName,
            objName: item.objName,
            isDeleted: item.isDeleted,
            size: item.size,
            isChecked: item.isChecked,
            isVirus: item.isVirus,
          };
        });
      }

      /** user agent */
      const ua = navigator.userAgent ? navigator.userAgent : "";
      connectionV1API();
      const res = await API.graphql(
        graphqlOperation(addTicket, {
          cognitoApiName: apiName.addTicket,
          tableName: process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME,
          input: {
            accountIdentifier: items.authInfo.accountIdentifier,
            inquiry: items.editedContent.inquiryContent,
            serviceId: items.editedContent.serviceId,
            incidentDate: items.editedContent.incidentDate.split("-").join("/"),
            incidentTime: items.editedContent.incidentTime,
            serviceCategory: items.editedContent.subCategory,
            userAgent: ua,
            occurrenceDate: items.occurrenceDate,
            filesInfo: files,
            uuid: items.uuid,
            openFaqIds: items.openFaqIds,
          },
        }),
        getAuthHeaderItems()
      );
      const resData = (res: any) => {
        return res.data;
      };
      // @ts-ignore
      return resData(res);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// 追加質問登録
export const fetchAsyncAddQuestionTicket = createAsyncThunk(
  "addQuestionTicket",
  async (
    {
      addInquiry,
      ticketNumber,
      status,
      chats,
      filesInfo,
    }: {
      addInquiry: string;
      ticketNumber: string;
      status: number;
      chats: string;
      filesInfo: any;
    },
    thunkAPI
  ) => {
    let files = null;
    if (filesInfo !== null && filesInfo.length !== 0) {
      files = filesInfo.map((item: any) => {
        return {
          fileName: item.fileName,
          objName: item.objName,
          isDeleted: item.isDeleted,
          size: item.size,
          isChecked: item.isChecked,
          isVirus: item.isVirus,
        };
      });
    }

    const input = {
      inquirySortKey: chats,
      addInquiry: addInquiry,
      ticketNumber: ticketNumber,
      status: status,
      filesInfo: files,
    };
    try {
      connectionV1API();
      const res = await API.graphql(
        graphqlOperation(addQuestionTicket, {
          cognitoApiName: apiName.addQuestionTicket,
          tableName: process.env.REACT_APP_AWS_DYNAMODB_TABLE_NAME,
          input: input,
        }),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
//サービス一覧取得
export const fetchAsyncServiceInfoList = createAsyncThunk(
  "fetchAsyncServiceInfoList",
  async (arg, thunkAPI) => {
    const filter = {
      telServiceFlg: { ne: true },
      releaseFlg: { ne: false },
    };
    const params = {
      partitionKey: "ServiceInfomation",
      filter,
    };
    try {
      const res = await API.graphql(
        graphqlOperation(fetchServiceNameList, params),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
//サービスName単体取得
export const fetchAsyncServiceName = createAsyncThunk(
  "fetchAsyncServiceName",
  async (sortKey: string, thunkAPI) => {
    try {
      const ModelUserStringKeyConditionInput = {
        eq: sortKey,
      };
      const res = await API.graphql(
        graphqlOperation(fetchServiceName, {
          sortKey: ModelUserStringKeyConditionInput,
        }),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
//チケット一覧取得(URLにサービスIDが存在しない)
export const fetchAsyncTicketInfomation = createAsyncThunk(
  "fetchAsyncTicketInfomation",
  async (accountIdentifier: string) => {
    // const ticketFilter = { status: { ne: 0 } };
    const ticketFilter = {
      or: [
        { status: { eq: 1 } },
        { status: { eq: 2 } },
        { status: { eq: 3 } },
        { status: { eq: 4 } },
        { status: { eq: 5 } },
        { status: { eq: 7 } },
        { status: { eq: 9 } },
      ],
    };
    const params = {
      partitionKey: "TicketInfomation",
      accountIdentifier: { eq: accountIdentifier },
      filter: ticketFilter,
      cognitoApiName: "userAcceptTicket",
      sortDirection: "DESC",
    };
    const res = await API.graphql(
      graphqlOperation(getTicketInfomation, params),
      getAuthHeaderItems()
    );
    //@ts-ignore
    return res.data;
  }
);
//チケット一覧取得(URLにサービスIDが存在する)
export const fetchAsyncTicketInfoServiceId = createAsyncThunk(
  "fetchAsyncTicketInfoServiceId",
  async (
    {
      accountIdentifier,
      serviceId,
    }: {
      accountIdentifier: string;
      serviceId: string;
    },
    thunkAPI
  ) => {
    const ticketFilter = {
      or: [
        { status: { eq: 1 } },
        { status: { eq: 2 } },
        { status: { eq: 3 } },
        { status: { eq: 4 } },
        { status: { eq: 5 } },
        { status: { eq: 7 } },
        { status: { eq: 9 } },
      ],
    };
    const ticketFilterServiceId = {
      and: [
        { userServiceId: { eq: serviceId } },
        {
          or: [
            { status: { eq: 1 } },
            { status: { eq: 2 } },
            { status: { eq: 3 } },
            { status: { eq: 4 } },
            { status: { eq: 5 } },
            { status: { eq: 7 } },
            { status: { eq: 9 } },
          ],
        },
      ],
    };
    const params = {
      partitionKey: "TicketInfomation",
      accountIdentifier: { eq: accountIdentifier },
      sortMatchKey: serviceId,
      filter: ticketFilter,
      filterServiceId: ticketFilterServiceId,
      cognitoApiName: "userAcceptTicket",
      sortDirection: "DESC",
    };
    try {
      const res = await API.graphql(
        graphqlOperation(getTicketInfoServiceId, params),
        getAuthHeaderItems()
      );
      //@ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
//追加質問画面：お問合せ内容
export const fetchAsyncAddQuestionResources = createAsyncThunk(
  "fetchAsyncAddQuestionResources",
  async (ticketNumber: string, thunkAPI) => {
    try {
      const chatsFilter =
        "attribute_exists(inquiry) OR attribute_exists(answer)";
      const res = await API.graphql(
        graphqlOperation(fetchAddQuestionResources, {
          partitionKey: "TicketInfomation",
          ticketNumber: ticketNumber,
          chatsFilter: chatsFilter,
          filePartitionKey: "Attached:" + ticketNumber,
        }),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// サブカテゴリ
export const fetchAsyncGetSubCategoryList = createAsyncThunk(
  "fetchAsyncGetSubCategoryList",
  async (serviceId: string, thunkAPI) => {
    try {
      connectionV2API();
      const res = await API.graphql(
        graphqlOperation(getSubCategoryList, { serviceIds: [serviceId] }),
        getAuthHeaderItems()
      );
      connectionV1API();
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// お知らせ一覧取得(URLにサービスIDが存在しない)
export const fetchGetNoticeList = createAsyncThunk(
  "fetchGetNoticeList",
  async (arg, thunkAPI) => {
    const noticeParams = {
      notification: "Notification",
    };
    try {
      const res = await API.graphql(
        graphqlOperation(getNoticeList, noticeParams),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// お知らせ一覧取得(URLにサービスIDが存在する)
export const fetchGetNoticeServiceIDList = createAsyncThunk(
  "fetchGetNoticeServiceIDList",
  async (serviceId: string, thunkAPI) => {
    const noticeFilterServiceId = { serviceId: { eq: serviceId } };

    const noticeServiceIdParams = {
      notification: "Notification",
      sortMatchKey: serviceId,
      filterServiceId: noticeFilterServiceId,
    };
    try {
      const res = await API.graphql(
        graphqlOperation(getNoticeServiceIdList, noticeServiceIdParams),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
export const fetchAsyncGetTicketInfoServiceId = createAsyncThunk(
  "getUserTicketInfomation",
  async (ticketNumber: string, thunkAPI) => {
    const params = {
      partitionKey: "TicketInfomation",
      ticketNumber: ticketNumber,
    };
    try {
      const res = await API.graphql(
        graphqlOperation(serviceIdFromTicketNumber, params),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// サービスIDに応じた戻り先URLの取得
export const fetchAsyncGetReturnUrl = createAsyncThunk(
  "getReturnUrl",
  async (serviceId: string, thunkAPI) => {
    const params = { serviceId };

    try {
      let res = await API.graphql(
        graphqlOperation(getReturnUrl, params),
        getAuthHeaderItems()
      );

      // @ts-ignore
      if (!res.data.returnUrl) {
        // 指定のサービスIDで取得できなかった場合は、デフォルトのURLを取得しなおす
        params.serviceId = DefaultReturnUrlKey;
        res = await API.graphql(
          graphqlOperation(getReturnUrl, params),
          getAuthHeaderItems()
        );
      }
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// サービスIDに応じた注意文言の取得
export const fetchAsyncGetAttentionMessage = createAsyncThunk(
  "getAttentionMessage",
  async (serviceId: string, thunkAPI) => {
    const params = {
      partitionKey: "AttentionMessage",
      sortKey: serviceId,
    };
    try {
      let res = await API.graphql(
        graphqlOperation(getAttentionMessage, params),
        getAuthHeaderItems()
      );
      // @ts-ignore
      if (!res.data.attentionMessage) {
        // 指定のサービスIDで取得できなかった場合は、デフォルトの注意文言を取得しなおす
        params.sortKey = DefaultAttentionMsgKey;
        res = await API.graphql(
          graphqlOperation(getAttentionMessage, params),
          getAuthHeaderItems()
        );
      }
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getTicketStatus = async (ticketNumber: string) => {
  const params = {
    partitionKey: "TicketInfomation",
    sortKey: ticketNumber,
  };
  try {
    return await API.graphql(graphqlOperation(getStatusInformation, params));
  } catch (e) {
    throw new Error("getTicketStatus error");
  }
};

/** 添付ファイル可能時用注意文言取得 */
export const fetchAsyncGetAttachmentMesseage = createAsyncThunk(
  "fetchAsyncGetAttachmentMesseage",
  async (arg, thunkAPI) => {
    const params = {
      partitionKey: "AttentionMessage",
      sortKey: "attachment",
    };
    try {
      connectionV1API();
      const res = await API.graphql(
        graphqlOperation(getAttachmentMesseage, params),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// 画像登録
export const addUploadImages = createAsyncThunk(
  "addUploadImages",
  async (
    {
      accountId,
      chatId,
      ticketId,
    }: {
      accountId: string;
      chatId: string;
      ticketId: string;
    },
    thunkAPI
  ) => {
    const params = {
      accountId,
      chatId,
      ticketId,
    };

    try {
      connectionV2API();
      const res = await API.graphql(
        graphqlOperation(uploadAllImages, params),
        getAuthHeaderItems()
      );
      connectionV1API();
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// 登録済み画像ダウンロード
export const fetchAsyncImageDownload = createAsyncThunk(
  "fetchAsyncImageDownload",
  async (
    {
      ticketNumber,
      chatNumber,
      objName,
    }: {
      ticketNumber: string;
      chatNumber: string;
      objName: string;
    },
    thunkAPI
  ) => {
    const params = {
      ticketNumber,
      chatNumber,
      objName,
    };

    try {
      connectionV2API();
      const res = await API.graphql(
        graphqlOperation(fetchImageDownload, params),
        getAuthHeaderItems()
      );
      connectionV1API();
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//  一時保存画像ダウンロード
export const fetchAsyncTemporarilyImage = createAsyncThunk(
  "fetchAsyncTemporarilyImage",
  async (
    {
      accountIdentifier,
      objName,
    }: {
      accountIdentifier: string;
      objName: string;
    },
    thunkAPI
  ) => {
    const params = {
      accountIdentifier,
      objName,
    };

    try {
      connectionV2API();
      const res = await API.graphql(
        graphqlOperation(fetchTemporarilyImage, params),
        getAuthHeaderItems()
      );
      connectionV1API();
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// 画像アップロード先署名付きURL取得
export const getUploadUrl = async ({
  accountId,
  fileName,
  fileType,
}: {
  accountId: string;
  fileName: string;
  fileType: string;
}) => {
  try {
    const params = {
      accountId,
      fileName,
      fileType,
    };

    connectionV2API();
    const res = await API.graphql(
      graphqlOperation(fetchImageUploadUrl, params)
    );
    connectionV1API();
    // @ts-ignore
    return res.data;
  } catch (e) {
    return {
      getUploadUrl: {
        statusCode: 500,
      },
    };
  }
};

// 画像アップロード可能サービス判定フラグ取得
export const fetchAsyncServiceImageFlag = createAsyncThunk(
  "fetchAsyncServiceImageFlag",
  async (sortKey: string, thunkAPI) => {
    const params = {
      partitionKey: "ServiceInfomation",
      sortKey,
    };
    try {
      connectionV1API();
      const res = await API.graphql(
        graphqlOperation(fetchServiceImageFlag, params),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// 画像投稿用注意文言取得
export const fetchAsyncImageNotes = createAsyncThunk(
  "fetchAsyncImageNotes",
  async (serviceId: string, thunkAPI) => {
    const params = {
      partitionKey: serviceId,
      sortKey: "AttachedNotes",
    };
    try {
      connectionV1API();
      const res = await API.graphql(
        graphqlOperation(fetchImageNotes, params),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//  チケット情報のサービスID取得
export const getServiceId = async (ticketNumber: string) => {
  try {
    const params = {
      partitionKey: "TicketInfomation",
      sortKey: ticketNumber,
    };
    connectionV1API();
    const res = await API.graphql(
      graphqlOperation(fetchTicketServiceId, params),
      getAuthHeaderItems()
    );
    // @ts-ignore
    return res.data;
  } catch (e) {
    throw new Error("getServiceId error");
  }
};

//  ウイルスチェック
export const fetchAsyncCheckImage = async ({
  accountId,
  objName,
  fileName,
  size,
}: {
  accountId: string;
  objName: string;
  fileName: string;
  size: string;
}) => {
  try {
    const params = {
      accountId,
      objName,
      fileName,
      size,
    };

    let res: any;
    let num = 1;
    let checkResult: any;
    const handleCheckImage = async () => {
      connectionV2API();
      res = await API.graphql(
        graphqlOperation(fetchCheckImage, params),
        getAuthHeaderItems()
      );
      connectionV1API();
      //@ts-ignore
      return res;
    };

    while (
      num === 1 ||
      (num <= 3 && res.data?.checkImage?.statusCode === 702)
    ) {
      num++;
      checkResult = await handleCheckImage();
    }
    // @ts-ignore
    return checkResult;
  } catch (e) {
    return {
      data: {
        checkImage: {
          statusCode: 500,
        },
      },
    };
  }
};

// 画像投稿ウイルスチェック進行確認フラグ取得
export const fetchAsyncCheckedFlag = createAsyncThunk(
  "fetchAsyncCheckedFlag",
  async (
    {
      ticketNumber,
      index,
      objName,
    }: {
      ticketNumber: string;
      index: string;
      objName: string;
    },
    thunkAPI
  ) => {
    const params = {
      partitionKey: "Attached:" + ticketNumber,
      sortKey: "Chat" + index,
      objName: objName,
    };
    try {
      let res: any;
      const handleCheckImage = async () => {
        connectionV2API();
        res = await API.graphql(
          graphqlOperation(checkDynamodb, params),
          getAuthHeaderItems()
        );
        connectionV1API();
        //@ts-ignore
        return res;
      };

      res = await handleCheckImage();
      while (res.data?.checkDynamodb?.statusCode === 702) {
        res = await handleCheckImage();
      }
      // @ts-ignore
      return { res: res.data, index: index };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// リビジョン取得
export const getUserRevision = createAsyncThunk(
  "getUserRevision",
  async (sortKey: string, thunkAPI) => {
    try {
      const params = {
        partitionKey: revisionKey,
        sortKey,
      };
      const res = await API.graphql(
        graphqlOperation(getRevision, params),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// サービス終了情報
export const getServiceCloseInfo = async (serviceId: string) => {
  try {
    const params = {
      partitionKey: "ServiceInfomation",
      sortKey: serviceId,
    };
    const res = await API.graphql(
      graphqlOperation(getServiceCloseFlag, params)
    );
    // @ts-ignore
    return res.data;
  } catch (e) {
    throw new Error("getServiceCloseInfo error");
  }
};

// サービス終了判定フラグ取得
export const fetchAsyncServiceCloseInfo = createAsyncThunk(
  "fetchAsyncServiceCloseInfo",
  async (serviceId: string, thunkAPI) => {
    const params = {
      partitionKey: "ServiceInfomation",
      sortKey: serviceId,
    };
    try {
      const res = await API.graphql(
        graphqlOperation(getServiceCloseFlag, params),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// FAQ 表示フラグ取得
export const getIsEnabledFAQData = createAsyncThunk(
  "getIsEnabledFAQData",
  async (serviceId: string, thunkAPI) => {
    const params = {
      serviceId: serviceId,
    };
    try {
      const res = await API.graphql(
        graphqlOperation(getIsEnabledFAQ, params),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// FAQ データ取得
export const fetchFaqDataFromSubCategoryId = createAsyncThunk(
  "fetchFaqDataFromSubCategoryId",
  async (subCategoryId: string, thunkAPI) => {
    const params = {
      key: subCategoryId,
    };
    try {
      const res = await API.graphql(
        graphqlOperation(fetchFaqData, params),
        getAuthHeaderItems()
      );
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// 操作ログ登録
export const addOperationLog = async (data: any) => {
  try {
    await API.graphql(graphqlOperation(addLog, data));
  } catch (e) {
    console.error(e);
  }
};

// 現在日時取得(タイムスタンプ Asia/Tokyo)
export const getCurrentTimeInfo = async () => {
  try {
    const res = await API.graphql(graphqlOperation(getCurrentTime));
    // @ts-ignore
    return res.data;
  } catch (e) {
    window.location.href = "/error?code=117";
  }
};

// 現在の日時を設定する(タイムスタンプ Asia/Tokyo)
export const fetchAsyncCurrentTimeInfo = createAsyncThunk(
  "fetchAsyncCurrentTimeInfo",
  async (arg, thunkAPI) => {
    try {
      const res = await API.graphql(graphqlOperation(getCurrentTime));
      // @ts-ignore
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const sort = (array: any) => {
  if (array === null) {
    return [];
  }
  return array.sort((a: any, b: any) => Number(a.index) - Number(b.index));
};

// 日付を降順に並べ替え
const sortCreatedAt = (descArray: any) => {
  if (descArray === null) {
    return [];
  }
  return descArray.sort(
    (a: any, b: any) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
  );
};
const sortCreatedAtServiceId = (descArray: any) => {
  if (descArray === null) {
    return [];
  }
  return descArray.sort(
    (a: any, b: any) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
  );
};
const sortScheduledAt = (descArray: any) => {
  if (descArray === null) {
    return [];
  }
  return descArray.sort(
    (a: any, b: any) => Date.parse(b.scheduledAt) - Date.parse(a.scheduledAt)
  );
};
const sortScheduledAtServiceId = (descArray: any) => {
  if (descArray === null) {
    return [];
  }
  return descArray.sort(
    (a: any, b: any) => Date.parse(b.scheduledAt) - Date.parse(a.scheduledAt)
  );
};

export const slice = createSlice({
  name: "all",
  initialState,
  reducers: {
    setIsRevisionMatch(state, action: PayloadAction<boolean>) {
      state.isRevisionMatch = action.payload;
    },
    setLoginType(state, action: PayloadAction<string>) {
      state.loginType = action.payload;
    },
    setUuid(state, action: PayloadAction<any>) {
      state.uuid = action.payload;
    },
    setAuthInfo(state, action: PayloadAction<any>) {
      state.authInfo.accountIdentifier = action.payload.accountIdentifier;
      state.authInfo.maskdocomoId = action.payload.maskdocomoId;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    handleDisplay(state, action: PayloadAction<number>) {
      state.displayNumber = action.payload;
    },
    handleDisplayAddQuestions(state, action: PayloadAction<number>) {
      state.displayNumberAdd = action.payload;
    },
    handleDButton(state, action: PayloadAction<boolean>) {
      state.displayDButton = action.payload;
    },
    setEditContentInitialize(state) {
      state.editedContent = {
        incidentDate: "",
        incidentTime: "",
        serviceId: "",
        accountIdentifier: "",
        inquiryContent: "",
        subCategory: SubCategoryUnselected,
      };
    },
    setChatsInitialize(state) {
      state.chats = [];
    },
    setimagesInitialize(state) {
      state.isFileAttache = false;
    },
    editContent(state, action: PayloadAction<EDITED_CONTENT>) {
      state.editedContent = action.payload;
    },
    setOccurrenceDate(state, action: PayloadAction<number>) {
      state.occurrenceDate = action.payload;
    },
    setAdditionalInquiryInitialize(state) {
      state.addInquiryContent = { addInquiry: "" };
    },
    additionalInquiry(state, action: PayloadAction<ADD_INQUIRY_CONTENT>) {
      state.addInquiryContent = action.payload;
    },
    // 流入元サービス存在フラグ
    setExistFromService(state, action: PayloadAction<boolean>) {
      state.existFromService = action.payload;
    },
    setDisplayServiceId(state, action: PayloadAction<string>) {
      state.displayServiceId = action.payload;
    },
    /** チケット情報初期化 */
    setTicketInfomationInitialize(state) {
      return {
        ...state,
        ticketInfomation: {
          ticketNumber: "",
          serviceId: "",
          serviceName: "",
          inquiry: "",
          createdAt: "",
          status: SubCategoryUnselected,
          hiddenFlg: false,
        },
      };
    },
    setImagePreview(state, action: PayloadAction<IMAGE_PREVIEW>) {
      state.imagePreview = action.payload;
    },
    setFailedImage(state, action: PayloadAction<boolean>) {
      state.failedImage = action.payload;
    },
    setAddImageFileInfo(state, action: PayloadAction<IMAGE_FILE_INFO>) {
      return {
        ...state,
        imageFileInfo: [...state.imageFileInfo, action.payload],
        uploadingFileFlag: true,
        waitingImageUp: true,
      };
    },
    setDeleteTargetImageFileInfo(state, action: PayloadAction<string>) {
      return {
        ...state,
        imageFileInfo: state.imageFileInfo.filter(
          (item) => item.objName !== action.payload
        ),
      };
    },
    // 投稿画像情報初期化
    setDeleteAllImageFileInfo(state) {
      return {
        ...state,
        imageFileInfo: [],
        imageNotes: [],
        failedImage: false,
        invalidFileFlag: false,
        uploadingFileFlag: false,
        waitingImageUp: false,
        loadingTransparent: false,
        isFileAttache: false,
        imagePreview: {
          url: "",
          open: false,
        },
      };
    },
    setChangeImageFileInfo(state, action: PayloadAction<IMAGE_FILE_INFO>) {
      const items = state.imageFileInfo.map((item) =>
        item.objName === action.payload.objName ? action.payload : item
      );
      const exitCheck = state.imageFileInfo.findIndex(
        (item) => item.objName === action.payload.objName
      );

      if (state.imageFileInfo.length === 0 || exitCheck === -1) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          imageFileInfo: items,
        };
      }
    },
    setCheckedImageFileInfo(state, action: PayloadAction<IMAGE_FILE_INFO>) {
      const changeInfo = state.imageFileInfo.findIndex(
        (item) => item.objName === "" && item.isDeleted === false
      );
      const items = state.imageFileInfo.map((item, index) =>
        index === changeInfo ? action.payload : item
      );

      if (state.imageFileInfo.length === 0 || changeInfo === -1) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          imageFileInfo: items,
        };
      }
    },
    setTicketNumberInitialize(state) {
      return {
        ...state,
        ticketNumber: "",
      };
    },
    setLoadingTransparent(state, action: PayloadAction<boolean>) {
      state.loadingTransparent = action.payload;
    },
    setInvalidFileFlag(state, action: PayloadAction<boolean>) {
      state.invalidFileFlag = action.payload;
    },
    setUploadingFileFlag(state, action: PayloadAction<boolean>) {
      state.uploadingFileFlag = action.payload;
    },
    setWaitingImageUp(state, action: PayloadAction<boolean>) {
      state.waitingImageUp = action.payload;
    },
    setChangeInvalidFileInfo(state, action: PayloadAction<IMAGE_FILE_INFO>) {
      const items = state.imageFileInfo.map((item) =>
        item.objName === action.payload.objName ? action.payload : item
      );
      const exitCheck = state.imageFileInfo.findIndex(
        (item) => item.objName === action.payload.objName
      );
      if (state.imageFileInfo.length === 0 || exitCheck === -1) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          imageFileInfo: items,
          invalidFileFlag: true,
          uploadingFileFlag: false,
          waitingImageUp: false,
        };
      }
    },
    setChangeFailedFileInfo(state, action: PayloadAction<string>) {
      return {
        ...state,
        imageFileInfo: state.imageFileInfo.filter(
          (item) => item.objName !== action.payload
        ),
        failedImage: true,
        waitingImageUp: false,
        uploadingFileFlag: false,
      };
    },
    setOpenFaqId(state, action: PayloadAction<string>) {
      // 重複させないためにSetを使用
      const faqIdSet = new Set(state.openFaqIds);
      faqIdSet.add(action.payload);
      return {
        ...state,
        openFaqIds: Array.from(faqIdSet),
      };
    },
    setFaqDataInitialize(state) {
      state.faqData = [];
    },
    setIsEnabledFAQInitialize(state) {
      state.isEnabledFAQ = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createSession.fulfilled, (state, action: any) => {
      if (action.payload.regenerationSession?.statusCode === "401") {
        authRedirect(authenticationType.general);
        throw new Error();
      } else {
        // cookie にセッション登録
        setSession(action.payload.regenerationSession);
      }
    });
    builder.addCase(createSession.rejected, (state, action: any) => {
      // code=087
      const code = "087";
      if (action.payload.errors) {
        isAuthError(action.payload.errors, code);
      }
    });
    builder.addCase(fetchAsyncAddTicket.fulfilled, (state, action: any) => {
      const ticketNumber = action.payload.addTicket.ticketNumber;
      return {
        ...state,
        ticketNumber: ticketNumber,
        openFaqIds: [],
        faqData: [],
        isEnabledFAQ: false,
      };
    });
    builder.addCase(fetchAsyncAddTicket.rejected, (state, action: any) => {
      if (action.payload.errors) {
        // バリデーションエラーの場合
        if (action.payload.errors[0].errorType === "validationError") {
          window.location.href = "/error?code=057";
        }
        // 登録失敗時はセッションに関係なくエラー画面に遷移
        window.location.href = "/error?code=058";
        throw new Error();
      }
    });
    builder.addCase(
      fetchAsyncServiceInfoList.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          // services: action.payload.services.items,
          /** 解約申請画面用対応 ************************************************************/
          // 解約申請画面用のサービスを表示しない用の対応
          services: action.payload.services.items.filter(
            (item: SERVICES) => item.serviceId !== CANCEL_SERVICE_ID
          ),
          /********************************************************************************/
        };
      }
    );
    builder.addCase(
      fetchAsyncServiceInfoList.rejected,
      (state, action: any) => {
        if (action.payload.errors) {
          // code=088
          const code = "088";
          isAuthError(action.payload.errors, code);
        }
      }
    );
    // 解約申請サービス用================================================
    builder
      .addCase(
        fetchAsyncTicketInfoCancel.fulfilled,
        (state: ALL_STATE, action: any) => {
          return {
            ...state,
            ticketInfoCancel: sortCreatedAtServiceId(
              action.payload.displayTicketInfoId.items
            ),
          };
        }
      )
      .addCase(fetchAsyncTicketInfoCancel.rejected, (_, action: any) => {
        if (action.payload.errors) {
          // code=089
          const code = "089";
          isAuthError(action.payload.errors, code);
        }
      });
    // ===================================================================
    builder.addCase(fetchAsyncServiceName.fulfilled, (state, action: any) => {
      return {
        ...state,
        serviceIdName: action.payload.serviceIdName.items,
      };
    });
    builder.addCase(fetchAsyncServiceName.rejected, (state, action: any) => {
      if (action.payload.errors) {
        // code=090
        const code = "090";
        isAuthError(action.payload.errors, code);
      }
    });
    builder.addCase(
      fetchAsyncTicketInfomation.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          displayTicketInfo: sortCreatedAt(
            action.payload.displayTicketInfo.items
          ),
        };
      }
    );
    builder.addCase(
      fetchAsyncTicketInfomation.rejected,
      (state, action: any) => {
        if (action.payload.errors) {
          // code=096
          const code = "096";
          isAuthError(action.payload.errors, code);
        }
      }
    );
    builder.addCase(
      fetchAsyncTicketInfoServiceId.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          displayTicketInfoId: sortCreatedAtServiceId(
            action.payload.displayTicketInfoId.items
          ),
        };
      }
    );
    builder.addCase(
      fetchAsyncTicketInfoServiceId.rejected,
      (state, action: any) => {
        if (action.payload.errors) {
          // code=091
          const code = "091";
          isAuthError(action.payload.errors, code);
        }
      }
    );
    builder.addCase(
      fetchAsyncAddQuestionResources.fulfilled,
      (state, action: any) => {
        if (action.payload.fileInfo?.items?.length !== 0) {
          action.payload.chats?.items?.forEach((item: any) => {
            action.payload.fileInfo?.items?.forEach((file: any) => {
              if (item.index === file.index.replace("Chat", "")) {
                const i = item;
                i.fileData = file?.data;
              }
            });
          });
        }
        return {
          ...state,
          ticketInfomation: action.payload.ticketInfomation,
          chats: sort(action.payload.chats.items),
        };
      }
    );
    builder.addCase(
      fetchAsyncAddQuestionResources.rejected,
      (state, action: any) => {
        if (action.payload.errors) {
          // code=092
          const code = "092";
          isAuthError(action.payload.errors, code);
        }
      }
    );
    builder.addCase(
      fetchAsyncGetSubCategoryList.fulfilled,
      (state, action: any) => {
        let subCategoryArray;
        if (!(action.payload.subCategories.items.length === 0)) {
          subCategoryArray = action.payload.subCategories.items.map((p: any) =>
            p.index.slice(0, 2) === "SV"
              ? { ...p, index: Number(p.index.slice(7)) }
              : { ...p, index: Number(p.index.slice(2)) }
          );
        }
        return {
          ...state,
          subCategories: subCategoryArray,
        };
      }
    );
    builder.addCase(
      fetchAsyncGetSubCategoryList.rejected,
      (state, action: any) => {
        if (action.payload.errors) {
          // code=093
          const code = "093";
          isAuthError(action.payload.errors, code);
        }
      }
    );
    builder.addCase(
      fetchAsyncAddQuestionTicket.fulfilled,
      (state, action: any) => {}
    );
    builder.addCase(
      fetchAsyncAddQuestionTicket.rejected,
      (state, action: any) => {
        window.location.href = "/error?code=059";
        throw new Error();
      }
    );
    builder.addCase(fetchGetNoticeList.fulfilled, (state, action: any) => {
      return {
        ...state,
        noticeListInfo: sortScheduledAt(action.payload.noticeListInfo.items),
      };
    });
    builder.addCase(fetchGetNoticeList.rejected, (state, action: any) => {
      if (action.payload.errors) {
        // code=094
        const code = "094";
        isAuthError(action.payload.errors, code);
      }
    });
    builder.addCase(
      fetchGetNoticeServiceIDList.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          noticeServiceIdListInfo: sortScheduledAtServiceId(
            action.payload.noticeServiceIdListInfo.items
          ),
        };
      }
    );
    builder.addCase(
      fetchGetNoticeServiceIDList.rejected,
      (state, action: any) => {
        if (action.payload.errors) {
          // code=095
          const code = "095";
          isAuthError(action.payload.errors, code);
        }
      }
    );
    builder.addCase(
      fetchAsyncGetTicketInfoServiceId.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          displayServiceId: action.payload.ticketInfomation.serviceId,
        };
      }
    );
    builder.addCase(
      fetchAsyncGetTicketInfoServiceId.rejected,
      (state, action: any) => {
        window.location.href = "/error?code=060";
      }
    );
    builder.addCase(fetchAsyncGetReturnUrl.fulfilled, (state, action: any) => {
      if (action.payload.returnUrl) {
        return {
          ...state,
          returnUrlInfo: action.payload.returnUrl,
        };
      }
    });
    builder.addCase(fetchAsyncGetReturnUrl.rejected, (state, action: any) => {
      window.location.href = "/error?code=061";
    });
    builder.addCase(
      fetchAsyncGetAttentionMessage.fulfilled,
      (state, action: any) => {
        if (action.payload.attentionMessage.message) {
          return {
            ...state,
            attentionMessage: action.payload.attentionMessage.message,
          };
        }
      }
    );
    builder.addCase(
      fetchAsyncGetAttentionMessage.rejected,
      (state, action: any) => {
        window.location.href = "/error?code=062";
      }
    );
    builder.addCase(fetchAsyncCancelTicket.fulfilled, (state, action: any) => {
      const ticketNumber = action.payload.addTicket.ticketNumber;
      return {
        ...state,
        ticketNumber: ticketNumber,
      };
    });
    builder.addCase(fetchAsyncCancelTicket.rejected, (state, action: any) => {
      if (action.payload.errors) {
        // バリデーションエラーの場合
        if (action.payload.errors[0].errorType === "validationError") {
          window.location.href = "/error?code=057";
        }
        // 登録失敗時はセッションに関係なくエラー画面に遷移
        window.location.href = "/error?code=058";
        throw new Error();
      }
    });
    builder.addCase(
      fetchAsyncGetAttachmentMesseage.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          attachmentMessage: action.payload.attachmentMessage?.message,
        };
      }
    );
    builder.addCase(
      fetchAsyncGetAttachmentMesseage.rejected,
      (state, action: any) => {
        window.location.href = "/error?code=100";
      }
    );
    builder.addCase(addUploadImages.fulfilled, (state, action: any) => {
      if (action.payload.uploadAllImages?.statusCode !== 200) {
        window.location.href = "/error?code=102";
      }
    });
    builder.addCase(addUploadImages.rejected, (state, action: any) => {
      if (
        action.payload.errors[0].errorType !==
        "Lambda:ExecutionTimeoutException"
      ) {
        window.location.href = "/error?code=103";
      }
    });

    builder.addCase(setUserAuthInfo.fulfilled, (state, action: any) => {
      return {
        ...state,
        authInfo: {
          ...state.authInfo,
          maskdocomoId: action.payload.userAuth.maskdocomoId,
        },
      };
    });
    builder.addCase(setUserAuthInfo.rejected, (state, action: any) => {
      return {
        ...state,
        authInfo: {
          ...state.authInfo,
          maskdocomoId: "取得に失敗しました",
        },
      };
    });
    builder.addCase(fetchAsyncImageDownload.fulfilled, (state, action: any) => {
      if (action.payload.downloadObjName?.statusCode === 200) {
        return {
          ...state,
          imagePreview: {
            url: action.payload.downloadObjName.url,
            open: true,
          },
        };
      } else {
        return {
          ...state,
          loadingTransparent: false,
          failedImage: true,
        };
      }
    });
    builder.addCase(fetchAsyncImageDownload.rejected, (state, action: any) => {
      return {
        ...state,
        loadingTransparent: false,
        failedImage: true,
      };
    });
    builder.addCase(
      fetchAsyncTemporarilyImage.fulfilled,
      (state, action: any) => {
        if (action.payload.downloadTemporarily?.statusCode === 200) {
          return {
            ...state,
            imagePreview: {
              url: action.payload.downloadTemporarily.url,
              open: true,
            },
          };
        } else {
          return {
            ...state,
            loadingTransparent: false,
            failedImage: true,
            waitingImageUp: false,
            uploadingFileFlag: false,
          };
        }
      }
    );
    builder.addCase(
      fetchAsyncTemporarilyImage.rejected,
      (state, action: any) => {
        return {
          ...state,
          loadingTransparent: false,
          failedImage: true,
          waitingImageUp: false,
          uploadingFileFlag: false,
        };
      }
    );
    builder.addCase(
      fetchAsyncServiceImageFlag.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          isFileAttache: action.payload.isFileAttache.isFileAttache,
        };
      }
    );
    builder.addCase(
      fetchAsyncServiceImageFlag.rejected,
      (state, action: any) => {
        if (action.payload.errors) {
          // code=104
          const code = "104";
          isAuthError(action.payload.errors, code);
        }
      }
    );
    builder.addCase(fetchAsyncImageNotes.fulfilled, (state, action: any) => {
      return {
        ...state,
        imageNotes: action.payload.imageNotes?.data,
      };
    });
    builder.addCase(fetchAsyncImageNotes.rejected, (state, action: any) => {
      if (action.payload.errors) {
        // code=105
        const code = "105";
        isAuthError(action.payload.errors, code);
      }
    });
    builder.addCase(fetchAsyncCheckedFlag.fulfilled, (state, action: any) => {
      if (action.payload.res.checkDynamodb?.statusCode === 200) {
        let fileItems: any = [];

        state.chats.forEach((chat) => {
          if (chat.index === action.payload.index) {
            chat.fileData?.forEach((file) => {
              if (
                file.objName === action.payload.res.checkDynamodb?.body.objName
              ) {
                fileItems.push({
                  ...file,
                  isChecked: action.payload.res.checkDynamodb?.body.isChecked,
                  isVirus: action.payload.res.checkDynamodb?.body.isVirus,
                });
              } else {
                fileItems.push(file);
              }
            });
          }
        });
        const items: any = state.chats.map((item) =>
          item.index === action.payload.index
            ? { ...item, fileData: fileItems }
            : item
        );
        return {
          ...state,
          chats: sort(items),
        };
      } else {
        return {
          ...state,
          loadingTransparent: false,
          failedImage: true,
        };
      }
    });
    builder.addCase(fetchAsyncCheckedFlag.rejected, (state, action: any) => {
      return {
        ...state,
        loadingTransparent: false,
        failedImage: true,
      };
    });
    builder.addCase(
      fetchAsyncServiceCloseInfo.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          serviceCloseFlag:
            action.payload.getCIMPF.serviceCloseFlag !== null
              ? action.payload.getCIMPF.serviceCloseFlag
              : false,
        };
      }
    );
    builder.addCase(
      fetchAsyncServiceCloseInfo.rejected,
      (state, action: any) => {
        window.location.href = "/error?code=114";
      }
    );

    builder.addCase(getUserRevision.fulfilled, (state, action: any) => {
      const revision =
        action.payload.release?.revision || sessionStorage.getItem("v");
      return {
        ...state,
        revision,
      };
    });
    builder.addCase(getUserRevision.rejected, (state, action: any) => {
      if (action.payload.errors) {
        // code=106
        const code = "106";
        isAuthError(action.payload.errors, code);
      }
    });
    builder.addCase(getIsEnabledFAQData.fulfilled, (state, action: any) => {
      const serviceInfomation = action.payload.serviceInfomation || {};
      const isEnabledFAQ = serviceInfomation.isEnabledFAQ || false;
      return {
        ...state,
        isEnabledFAQ,
      };
    });
    builder.addCase(getIsEnabledFAQData.rejected, (state, action: any) => {
      if (action.payload.errors) {
        // code=107
        const code = "107";
        isAuthError(action.payload.errors, code);
      }
    });
    builder.addCase(
      fetchFaqDataFromSubCategoryId.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          faqData: action.payload.faqData,
        };
      }
    );
    builder.addCase(
      fetchFaqDataFromSubCategoryId.rejected,
      (state, action: any) => {
        if (action.payload.errors) {
          // code=108
          const code = "108";
          isAuthError(action.payload.errors, code);
        }
      }
    );
    builder.addCase(
      fetchAsyncCurrentTimeInfo.fulfilled,
      (state, action: any) => {
        const todayString = action.payload.getCurrentTime.currentTime;
        const todayArray = todayString.split(" ");

        //現在日付取得関数
        state.editedContent.incidentDate = todayArray[0];
        //現在時刻取得関数
        state.editedContent.incidentTime = todayArray[1];
      }
    );
    builder.addCase(
      fetchAsyncCurrentTimeInfo.rejected,
      (state, action: any) => {
        window.location.href = "/error?code=116";
      }
    );
  },
});
const isAuthError = (e: any, code: string = "xxx") => {
  let massege = "";
  if (e.length && e[0].errorType === authConst.notFoundSessionError) {
    authRedirect(authenticationType.general);
    massege = e[0].message;
  } else {
    window.location.href = `/error?code=${code}`;
  }
  /* 例外をスローして処理中断 */
  throw new Error(massege);
};
export const {
  setIsRevisionMatch,
  setUuid,
  setAuthInfo,
  setLoading,
  handleDisplay,
  handleDisplayAddQuestions,
  handleDButton,
  editContent,
  additionalInquiry,
  setOccurrenceDate,
  setExistFromService,
  setDisplayServiceId,
  setEditContentInitialize,
  setAdditionalInquiryInitialize,
  setTicketInfomationInitialize,
  setLoginType,
  setimagesInitialize,
  setImagePreview,
  setFailedImage,
  setAddImageFileInfo,
  setDeleteTargetImageFileInfo,
  setChangeImageFileInfo,
  setCheckedImageFileInfo,
  setDeleteAllImageFileInfo,
  setTicketNumberInitialize,
  setLoadingTransparent,
  setInvalidFileFlag,
  setChangeInvalidFileInfo,
  setChangeFailedFileInfo,
  setUploadingFileFlag,
  setWaitingImageUp,
  setChatsInitialize,
  setOpenFaqId,
  setFaqDataInitialize,
  setIsEnabledFAQInitialize,
} = slice.actions;
export const authInfo = (state: RootState) => state.all.authInfo;
export const loading = (state: RootState) => state.all.loading;
export const selectDisplayNumber = (state: RootState) =>
  state.all.displayNumber;
export const selectDisplayNumberAdd = (state: RootState) =>
  state.all.displayNumberAdd;
export const selectDButton = (state: RootState) => state.all.displayDButton;
export const selectDaccount = (state: RootState) => state.all.daccount;
export const selectNoticeListInfo = (state: RootState) =>
  state.all.noticeListInfo;
export const selectNoticeServiceIdListInfo = (state: RootState) =>
  state.all.noticeServiceIdListInfo;
export const selectInquiries = (state: RootState) => state.all.inquiries;
export const selectServiceNameList = (state: RootState) =>
  state.all.serviceNameList;
export const selectEditedContent = (state: RootState) =>
  state.all.editedContent;
export const selectAddInquiryContent = (state: RootState) =>
  state.all.addInquiryContent;
export const selectDate = (state: RootState) =>
  state.all.editedContent.incidentDate;
export const selectTime = (state: RootState) =>
  state.all.editedContent.incidentTime;
export const selectSubCategory = (state: RootState) =>
  state.all.editedContent.subCategory;
export const selectOccurrenceDate = (state: RootState) =>
  state.all.occurrenceDate;
export const selectInquiryContent = (state: RootState) =>
  state.all.editedContent.inquiryContent;
export const selectInquiryAnswerList = (state: RootState) =>
  state.all.inquiryAnswerList;
export const selectQuestionDetailList = (state: RootState) =>
  state.all.questionDetailList;
export const selectAddInquiry = (state: RootState) =>
  state.all.addInquiryContent.addInquiry;
export const selectServiceList = (state: RootState) => state.all.services;
export const selectServiceName = (state: RootState) => state.all.serviceIdName;
export const selectTicketNumber = (state: RootState) => state.all.ticketNumber;
export const selectDisplayTicketInfo = (state: RootState) =>
  state.all.displayTicketInfo;
export const selectDisplayTicketInfoId = (state: RootState) =>
  state.all.displayTicketInfoId;
export const selectTicketInfomation = (state: RootState) =>
  state.all.ticketInfomation;
export const selectChats = (state: RootState) => state.all.chats;
export const selectSubCategories = (state: RootState) =>
  state.all.subCategories;
export const selectExistFromService = (state: RootState) =>
  state.all.existFromService;
export const selectDisplayServiceId = (state: RootState) =>
  state.all.displayServiceId;
export const selectReturnUrlInfo = (state: RootState) =>
  state.all.returnUrlInfo;
export const selectAttentionMessage = (state: RootState) =>
  state.all.attentionMessage;
export const loginType = (state: RootState) => state.all.loginType;
export const selectTicketInfoCancel = (state: RootState) =>
  state.all.ticketInfoCancel;
export const selectAttachmentMessage = (state: RootState) =>
  state.all.attachmentMessage;
export const selectImageFileInfo = (state: RootState) =>
  state.all.imageFileInfo;
export const selectImagePreview = (state: RootState) => state.all.imagePreview;
export const selectFailedImage = (state: RootState) => state.all.failedImage;
export const loadingTransparent = (state: RootState) =>
  state.all.loadingTransparent;
export const selectIsFileAttache = (state: RootState) =>
  state.all.isFileAttache;
export const selectImageNotes = (state: RootState) => state.all.imageNotes;
export const selectInvalidFileFlag = (state: RootState) =>
  state.all.invalidFileFlag;
export const selectUploadingFileFlag = (state: RootState) =>
  state.all.uploadingFileFlag;
export const selectWaitingImageUp = (state: RootState) =>
  state.all.waitingImageUp;
export const selectServiceCloseFlag = (state: RootState) =>
  state.all.serviceCloseFlag;
export const selectRevision = (state: RootState) => state.all.revision;
export const selectIsRevisionMatch = (state: RootState) =>
  state.all.isRevisionMatch;
export const selectIsEnabledFAQ = (state: RootState) => state.all.isEnabledFAQ;
export const selectFaqData = (state: RootState) => state.all.faqData;
export const selectUuid = (state: RootState) => state.all.uuid;
export const selectOpenFaqIds = (state: RootState) => state.all.openFaqIds;
export default slice.reducer;
