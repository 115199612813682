import React from "react";

import Typography from "@mui/material/Typography";
import styles from "styles/Footer.module.css";
import { path } from "common/Route";
import { Link } from "@mui/material";

const Footer: React.FC = () => {
  return (
    <div className={styles.footer}>
      <Typography
        variant="body2"
        align="center"
        sx={{
          textTransform: "none",
          bgcolor: process.env.REACT_APP_COLOR_PRIMARY_MAIN,
          pt: "5px",
          pb: "2px",
        }}
      >
        <Link
          target="_blank"
          href={`${path.aboutdatacollection}`}
          color="#FAFAFA"
        >
          お客さまご利用端末からの情報の外部送信について
        </Link>
      </Typography>
      <Typography
        variant="body2"
        align="center"
        sx={{
          textTransform: "none",
          bgcolor: process.env.REACT_APP_COLOR_PRIMARY_MAIN,
          color: "#FAFAFA",
          py: "2px",
        }}
      >
        {"© NTT DOCOMO"}
      </Typography>
    </div>
  );
};
export default Footer;
